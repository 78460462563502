import React from 'react'
import { useStaticQuery, Link } from "gatsby"
import Layout from '../components/layout'
import Footer from '../components/footer'
import Intro from '../components/intro'
import Phone from '../assets/img/icons/phone_24px_outlined.svg'
import Mail from '../assets/img/icons/mail_24px_outlined.svg'
import MailWhite from '../assets/img/icons/mail_24pxwhite.svg'
import { Waypoint } from 'react-waypoint'
import FacebookBlack from "../assets/img/icons/facebook-b.svg"
import InstagramBlack from "../assets/img/icons/instagram-b.svg"
import TwitterBlack from "../assets/img/icons/twitter-b.svg"
import LinkedInBlack from "../assets/img/icons/linkedin-b.svg"
import GoogleBlack from "../assets/img/icons/google-b.svg"

const ContactPage = () => {

    let mail = "mailto:"

    const data = useStaticQuery(graphql`
    query {
        allContentfulContactpersonen {
            edges {
              node {
                naam
                functie
                email
              }
            }
          }
    }
  `)

    return (
        <Layout>
            <Intro title="Heb je nog vragen?" text="Heb je nog vragen? Schroom dan niet om contact met ons op te nemen. Wij staan je graag te woord!" />
            <iframe title="google" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9855.681157107338!2d4.5219852!3d51.8624118!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2c570e33f0dc5bb2!2sWork4Media!5e0!3m2!1snl!2snl!4v1595505977749!5m2!1snl!2snl">
            </iframe>
            <div className="inner">
                <div className="contact-wrapper" id="js-contact-wrapper">
                    <Waypoint bottomOffset="200px" onEnter={() => {
                        let currentElement = document.getElementById("js-contact-wrapper");
                        currentElement.classList.add('show');
                    }} />
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <div className="contact-block">
                                <h3>Contact</h3>
                                <div className="contact-place-details">
                                    <span>Work4Media</span><br></br>
                                    <span>Bijdorp-Oost 42</span><br></br>
                                    <span>2992 LA Barendrecht</span><br></br><br></br>
                                    <span>Postbus 215</span><br></br>
                                    <span>2990 AE Barendrecht</span><br></br><br></br>
                                    <span>KvK: 55565913</span>
                                    <div className="contact-touch-details">
                                        <img src={Phone} alt=""/>
                                        <span>085 - 111 78 90</span>
                                    </div>
                                    <div className="contact-touch-details">
                                        <img src={Mail} alt=""/>
                                        <a href="mailto:info@work4media.nl">info@work4media.nl</a>
                                    </div>
                                    <p className="flat-text">Wilt je informatie, een abonnement of adverteren? Je kunt ons bellen via 085-1117890 of vul het naastgelegen formulier in en wij nemen z.s.m. contact met je op.</p>
                                    <div className="social-links">
                                        <a href="https://www.facebook.com/Work4Media" target="_blank" rel="noreferrer"><img src={FacebookBlack} alt=""></img></a>
                                        <a href="https://www.instagram.com/industriebouw_/" target="_blank" rel="noreferrer"><img src={InstagramBlack} alt=""></img></a>
                                        <a href="https://twitter.com/media_work4" target="_blank" rel="noreferrer"><img src={TwitterBlack} alt=""></img></a>
                                        <a href="https://www.linkedin.com/company/29019397/admin/" target="_blank" rel="noreferrer"><img src={LinkedInBlack} alt=""></img></a> 
                                        {/* <a to="#"><img src={GoogleBlack} alt=""></img></a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <div className="contact-form-wrapper">
                                <h3>Stel uw vraag</h3>
                                <div className="form-wrapper">
                                    <form name="ContactPagina" method="POST" data-netlify="true">
                                    <input type="hidden" name="form-name" value="ContactPagina" />
                                        <div className="form-inputs">
                                            <label>
                                                <span>Naam:</span> <br></br><input type="text" name="naam" required/><br></br>
                                            </label>
                                            <label>
                                                <span>Email:</span> <br></br><input type="email" name="email" required/><br></br>
                                            </label>
                                        </div>
                                        <div className="form-inputs">
                                            <label>
                                                <span>Telefoon:</span> <br></br><input type="text" name="telefoon" /><br></br>
                                            </label>
                                            <label>
                                                <span>Onderwerp:</span> <br></br><input type="text" name="onderwerp" /><br></br>
                                            </label>
                                        </div>
                                        <label>
                                            Bericht: <br></br><input type="textarea" name="bericht" /><br></br>
                                        </label>
                                        <button type="submit">Verzend</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact-persons-wrapper" id="js-contact-persons-wrapper">
                <div className="wrapper-overlay"></div>
                <Waypoint bottomOffset="200px" onEnter={() => {
                    let currentElement = document.getElementById("js-contact-persons-wrapper");
                    currentElement.classList.add('show');
                }} />
                <div className="contact-persons">
                    <div className="inner">
                        <div className="row">
                            <div className="col-xs-12">
                                <h3>Wil je persoonlijk iemand spreken?</h3>
                            </div>
                        </div>
                        <div className="row">
                            {data.allContentfulContactpersonen.edges.map((edge, i) => {
                                return (
                                    <div className="col-xs-12 col-sm-6 col-lg-3" key={i}>
                                        <div className="contact-person">
                                            <span className="contact-person-name">{edge.node.naam}</span>
                                            <span>{edge.node.functie}</span>
                                            <div className="contact-touch-details">
                                                <img src={MailWhite} alt=""/>
                                                <a href={mail + edge.node.email}>{edge.node.email}</a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Layout>
    )
}

export default ContactPage;
